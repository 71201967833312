<script lang="ts" setup>
import { useElementSize } from '@vueuse/core'

export type TVariant = 'SideList' | 'ArticleList'
export type TSize = 'large' | 'small'

export interface TArticle {
  id: string | number
  title: string
  to: string
  publishedDate?: string | null
  publishedTime?: string | null
  thumbnail?: {
    url: string
    width: number
    height: number
  } | null
  thumbnailLarge?: {
    url: string
    width: number
    height: number
  } | null
  description?: string | null
  tags?: { title: string }[] | null
}

const props = defineProps({
  article: {
    type: Object as PropType<TArticle>,
    required: true
  },
  variant: {
    type: String as PropType<TVariant>,
    default: 'ArticleList'
  },
  size: {
    type: String as PropType<TSize>,
    default: 'large'
  },
  headingFontSize: {
    type: String,
    default: 'text-h5m  md:text-h4'
  }
})

const imageWrapperClassesMap: Record<TVariant, string> = {
  SideList: 'mr-[1.25rem] h-[5.625rem] w-[7.5rem]',
  ArticleList: ''
}

const card = ref<HTMLDivElement | null>(null)
const cardDimensions = useElementSize(card)

const emit = defineEmits(['mouseenter', 'mouseleave'])
const hovering = ref(false)
function handleMouseEnter() {
  hovering.value = true
  emit('mouseenter')
}
function handleMouseLeave() {
  hovering.value = false
  emit('mouseleave')
}
</script>

<template>
  <div
    ref="card"
    class="flex"
    :class="{
      'flex-row': props.variant === 'SideList',
      'flex-col md:flex-row': props.variant === 'ArticleList' && props.size === 'large',
      'flex-col gap-[1.25rem]': props.variant === 'ArticleList' && props.size === 'small'
    }"
    @mouseenter="handleMouseEnter"
    @mouseleave="handleMouseLeave"
  >
    <div
      class="flex-0 rounded-card"
      :class="[
        imageWrapperClassesMap[props.variant],
        {
          'flex-0 mx-auto max-h-[18.75rem] w-full max-w-[37.5rem] overflow-hidden rounded-card md:mr-[1.88rem] md:h-[7.8125rem] md:w-[15.625rem]':
            props.variant === 'ArticleList' && props.size === 'large'
        },
        {
          'article-cover-image': hovering
        }
      ]"
      :style="{
        viewTransitionName: hovering ? 'article-cover-image' : ''
      }"
    >
      <slot
        v-if="props.variant === 'SideList' ? props.article.thumbnail : props.article.thumbnailLarge"
        name="article-img"
        :thumbnail="
          props.variant === 'SideList' ? props.article.thumbnail : props.article.thumbnailLarge
        "
        :card-height="cardDimensions.height.value"
        :card-width="cardDimensions.width.value"
      ></slot>
    </div>
    <div
      :class="{
        'flex flex-1 flex-col': props.variant === 'SideList',
        'mt-[1.88rem] flex flex-1 flex-col gap-[0.62rem] md:mt-0':
          props.variant === 'ArticleList' && props.size === 'large'
      }"
    >
      <div>
        <div class="flex items-start justify-between">
          <NuxtLinkLocale
            :to="props.article.to"
            :class="`cursor-pointer font-medium hover:underline ${props.headingFontSize}`"
            prefetch
          >
            {{ props.article.title }}
          </NuxtLinkLocale>
        </div>
      </div>
      <div v-if="props.variant === 'ArticleList' && props.size === 'large'" class="grid">
        <p
          class="mb-[0.62rem] line-clamp-2 items-stretch text-t5 font-light md:line-clamp-1 lg:mb-0"
        >
          {{ props.article.description }}
        </p>
      </div>
      <div
        v-if="props.variant === 'ArticleList' && props.size === 'large'"
        class="mt-[1.25rem] flex flex-row gap-[0.31rem]"
      >
        <div
          v-for="tag in props.article.tags"
          :key="tag.title"
          class="rounded-tag bg-secondary-1 px-[0.62rem] py-[0.31rem] text-t6 font-light"
        >
          {{ tag.title }}
        </div>
      </div>
    </div>
    <slot name="date"></slot>
  </div>
</template>
